.navContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 4px solid red;
    margin-top: 0;
    /* background-color: #6495ED; */
    background-color: #CDF0EA;
}
.navContainer1{
    /* background-color: palevioletred; */
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    /* height: 16vh; */
}

.imageContainer1 {
    width: 20%;
    height: 14vh;
    margin-top: 1vh;
    /* background-color: blue; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-family: 'Arial', sans-serif;
}
.imageContainer1 h3{
    /* color: #2E3B4E; */
    color:black;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    padding-bottom: .1vh;
}
.imageContainer1 span{
    color: red;
}
.imageContainer1 p{
    /* color: #F39C12; */
    color:black;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding-top: 0;
    
}

.imageContainer1 img {
    width: 30%;
    height: 30%;
}

.inputBox {
    width: 60%;
    height:15vh;
    /* background-color: yellow; */
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
}

.inputBox input {
    display: flex;
    width: 50%;
    height: 40%;
    padding: 1%;
    display: flex;
    font-size: 16px;
    box-sizing: border-box;
    outline: none;
    border: none;
    background-color: #f5f5f5;
}

.inputBox #searchIcon {
    color: white;
    pointer-events: none;
    width: 5vw;
    height: 36.3px;
}

.inputBox .iconDiv {
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7%;
    height: 40%
}

.iconDiv #searchIcon1 {
    width: 5vw
}

.inputBox #categoryDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    height: 39.8%;
}

#selectTag {
    background-color: #E8E8E8;
    border: none;
    font-size: 10px;
    height: 100%;
    text-align: center;
    width: 100%;
    outline: none;
}

.cartContainer {
    width: 20%;
    height:15vh;
    /* background-color: red; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.cartContainer button {
    background-color: red;
    border: none;
}

.menuContainer {
    width: 100%;
    /* background-color: blue; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 1vh;
    
}

.navContainer #quoteButton {
    width: 100%;
    height: 4.5vh;
    border-radius: 7px;
    font-size: 1.1rem;
    margin: 0 2px;
    background-color: black;
    color: white;
}


.menu ul{
    list-style-type: none;
    display: flex;
    gap:1.5rem;
}
.menu ul li {
    /* margin-right: 20px; */
    cursor: pointer;
    font-weight: 500;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    cursor: pointer;
    
}
.menu li a {
    color: black;
    text-decoration: none;
    padding: 10px;
    cursor: pointer;
  }
  
  /* .menu li a:hover {
    background-color: red;
    color: white;
  } */
/* .hamburger{
    display: flex;
    flex-direction: column;
    background-color: red;
    width:5vw
}
.hamburger #bar{
    color:black;
    width:100%;
    height: 1vh;
    padding-top: 1vh;
}
#bar{
    width:2vw;
    border:1px solid black;
} */
 
 /* Dropdown for products starts here */

 .searchBox {
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .categoriesDropdown {
    width: 100%;
    height: 100%;
    font-size: 1rem;
  }
  
  .categoriesDropdown ul {
    display: grid;
    grid-template-columns: repeat(3, minmax(25%, 1fr)); 
    gap: 5px; 
    grid-template-rows: repeat(5, auto);
    padding-top: 1vh;
  }
  
  .categoriesDropdown li {
    list-style: none;
    padding: 3px; 
    /* background-color: #f9f9f9; */
    border-radius: 8px; 
    
    transition: transform 0.2s ease, box-shadow 0.2s ease; 
    cursor: pointer; 
  }
  
  .categoriesDropdown li:hover {
    transform: translateY(-2px); 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    background-color: #e0e0e0;
  }
  
  .categoriesDropdown ul li {
    font-size: 1rem;
  }
  
  
 
  
  
 /* Dropdown for products ends here */
@media (max-width: 480px) {
    .navContainer{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 13vh;
    }
    .navContainer img{
        width:65%;
        height: 50%;
    }
    .inputBox {
        width: 60%;
        border: none;
        outline: none;
        /* background-color: yellow; */
        height: 12vh;
    }
    .cartContainer button{
        display: none;
    }
    .cartIcon{
        display: none;
    }
   .cartTotal{
    display: none;
   }
    .inputBox #categoryDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
        border-radius: 0;
        border: none;
        /* background-color: red; */
        height: 40%;
        width:37.5%;
        border-right: .1px solid lightblue;

    }
    #categoryDiv  select{
        border: none;
        border-radius: 0;
        outline: none;
        background-color: #f5f5f5;
    }
    .inputBox input{
        display: flex;
        justify-content: center;
        text-align: center;
        outline: none;
        border: none;
        /* background-color: red; */
        border-radius: 0;
        font-size: .35em;
        padding-left:0;
        height: 40%;
        background-color: #f5f5f5;
        /* margin-right: 1vw; */
    }
    .iconDiv{
        /* background-color: green; */
        height: 40%;
        width:13%;
        border: none;
    }
    .iconDiv #searchIcon1{
        width: 50%;
        height:50%;
    }
.cartContainer .hamburger{
    width:45%;
    height:auto;
}
    .hamburger {
        /* background-color: red; */
        display: flex;
        flex-direction: column;
        width: 20%;
        cursor: pointer;
        /* background-color: yellow; */
        display: flex;
        justify-content: center;
        align-items: center;
     
    }
    
    .hamburger.open {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 10;
    }

    #bar {
        display: flex;
        /* flex-direction: column; */
        width: 100%;
        height: 5px;
        margin-top: 5px;
        transition: all 0.3s ease-in-out;
        background-color: #000;
        /* margin-top: 1px; */
    }
    .menu {
        display: none; /* Hide menu by default */
        flex-direction: column;
        position: absolute;
        top: 95px;
        right: 0;
        background-color: #f5f5f5;
        width: 200px;
        z-index: 500;
        /* transform: translateX(100%);
        transition: transform 1s ease-in-out; */
      }
      .menu.open {
        display: flex;
        /* transform: translateX(0%);  */
        transition: transform 1s ease-out;
      }
    
      .menu ul {
        flex-direction: column;
      }
    
      .menu li {
        border-bottom: 1px solid #444;
        margin: 0;
      }
    
      .menu li a {
        color: black;
        padding: 10px;
        display: block;
      }
    
      /* .menu li a:hover {
        background-color: #555;
      } */
       .menu.open .cartIconDiv1{
        /* background-color: red; */
        display: flex;
        justify-content: flex-end;
        padding-right: 1vw;
        padding-top: 1vh;
       }
       .cartIconDiv1 #cartTotal1{
        color:white;
        position: relative;
        right: 4vw;
        bottom: .5vh;
        font-size: 10px
       }
    }
